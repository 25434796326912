'use strict'
import $ from "jquery";
import popper from "popper.js";
import bootstrap from "bootstrap";
import Inputmask from "inputmask";
import swal from "sweetalert";
import { load } from 'recaptcha-v3';
import anime from 'animejs/lib/anime.es.js';
window.jQuery = $;
var slick = require("slick-carousel");
require("@fancyapps/fancybox");
require("dragscroll");



$(function() {
    const host = '';
    const $body = $('body');
    //phone mask
    // const im = new Inputmask("+7 999-999-99-99");
    const im = new Inputmask({
        mask: "+7 099-999-99-99",
        onBeforePaste: function (pastedValue, opts) {
            return pastedValue.replace(/\D/g, '').slice(-10);
        },
        definitions: {
            "0": {
                validator: "[0-69]"
            }
        },
    });
    im.mask($('.phone'));
    const iml = new Inputmask("9999999999");
    const imcc = new Inputmask("9999");
    const spnPopupShow = function(name){
        $('#popup-block').fadeIn(100, function(){
            const $new_content = $('[data-popblock="' + name + '"]').clone();
            $new_content.appendTo($('.popup-block__content').empty());
            $body.addClass('popup');
            iml.mask($new_content.find('.login-phone'));
            im.mask($new_content.find('.phone-mask'));
            imcc.mask($new_content.find('.confirm-code-mask'));
            $new_content.find('a[href="/registration.html"]').on('click', function(e){
                e.preventDefault();
                location.href=$(this).attr('href');
            });
            $new_content.find('button[type="submit"]').on('click', function(e){
                e.preventDefault();
                ajaxFormSubmit($(this).closest('form'));
            });
        });
    },
    correctHeight = () => {
        $('.inner-white').height($('.inner-block').outerHeight());
    };
    const doNotShowBtns = typeof doNotShowBtnsAtTop !== 'undefined' && doNotShowBtnsAtTop === true;
    const $showButtons = $('.showbuttons');
    let showButtons_is = null;
    function ajaxFormSubmit($form) {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var email = $form.find('[name="email"]');
        if (email.length && re.test(email)) {
            return options.onError();
        }
        const $sub_btn = $(this).find('button[type="submit"]');
        if ($sub_btn.data('loading')) return;
        $sub_btn.data('loading', true);
        if ($form.attr('data-jsonrpc')) {
            var unindexed_array = $form.serializeArray();
            var indexed_array = {};

            $.map(unindexed_array, function(n, i){
                indexed_array[n['name']] = n['value'];
            });
            if ($form.attr('action') === 'Session.signin') {
                indexed_array["is_subagent"]=true;
            }
            var request$ = $.ajax({
                'url': host + "/api/v1",
                'contentType': "application/json",
                'dataType': "text",
                'data': JSON.stringify({
                    method: $form.attr('action'),
                    jsonrpc: "2.0",
                    id: uuidv4(),
                    params: indexed_array
                }),
                'type': 'POST',
            });
        } else {
            var request$ = $.ajax({
                'url': $form.attr('action'),
                'type': $form.attr('method'),
                'data': $form.serializeArray(),
            });
        }
        request$.done(function(response) {
            response = JSON.parse(response);
            const falseResult = response.hasOwnProperty('result') && response.result === false,
                errText = response?.error?.data?.clear_message || '';
            if (!response.hasOwnProperty('error') && falseResult === false) {
                if ($form.attr('action') === 'Session.signin') {
                    localStorage.removeItem('asterisk-web-user-4a087b99-1a16-4db6-a318-50dbc134091c');
                    localStorage.clear();
                    localStorage.setItem('asterisk-web-user-4a087b99-1a16-4db6-a318-50dbc134091c', JSON.stringify(response.result.storage));
                    location.href = '/dashboard';
                } else if ($form.attr('action') === 'Session.password_recovery') {
                    $form.addClass('d-none');
                    $form.next().removeClass('d-none').find('input[name="phone"]').val(
                        $form.find('input[name="phone"]').val()
                    );
                    $form.next().find('label').text('Введите код подтверждения из ' + response.result.type_send);
                    swal({
                        title: 'Подтверждение',
                        text: response.result.message,
                        icon: "success",
                        button: {
                            text: "Понятно",
                        },
                    });
                } else if ($form.attr('action') === 'Session.password_recovery_confirm') {
                    localStorage.removeItem('asterisk-web-user-4a087b99-1a16-4db6-a318-50dbc134091c');
                    localStorage.clear();
                    localStorage.setItem('asterisk-web-user-4a087b99-1a16-4db6-a318-50dbc134091c', JSON.stringify(response.result.storage));
                    location.href = '/subagent/workers/edit/' + response.result.id;
                } else {
                    swal({
                        title: 'Готово',
                        text: 'Ваша заявка принята, наши менеджеры свяжутся с Вами в ближайшее время',
                        icon: "success",
                        button: {
                            text: "Понятно",
                        },
                    });
                    $form.reset();
                }
            } else {
                swal({
                    title: 'Ошибка',
                    text: errText,
                    icon: "warning",
                    button: {
                        text: "Понятно",
                    },
                });
            }
            $sub_btn.data('loading', false);
        });
        request$.fail(function(error) {
            const response = JSON.parse(error.responseText);
            if (!response || !response.hasOwnProperty('error')) {
                swal({
                    title: 'Ошибка',
                    text: 'Системная ошибка',
                    icon: "warning",
                    button: {
                        text: "Понятно",
                    },
                });
            } else {
                swal({
                    title: 'Ошибка',
                    text: response.error.data.clear_message,
                    icon: "warning",
                    button: {
                        text: "Понятно",
                    },
                });
            }
            $sub_btn.data('loading', false);
        });
    }

    function uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    //animation delay
    $('[data-delay]').each(function(){
        $(this).css({
            animationDelay : $(this).data('delay'),
        }).addClass('active');
    });

    //fixed menu
    var menuPosition = $('.menu').offset().top - 17;
    function fixedMenu() {
        if ($(window).scrollTop() > menuPosition) {
            $('.menu').addClass('fixed');
        } else {
            $('.menu').removeClass('fixed');
        }
    }
    fixedMenu();

    //yellow block
    $('.yellow-left__cross').click(function(e){
        e.preventDefault();
        $(this).parent().fadeOut();
    });

    //form
    $('[data-ajax-form]').each(function() {
        $(this).submit(function(e) {
            e.preventDefault();
            ajaxFormSubmit($(this));
        });
    });

    //recaptcha
    /*    load('6LeAlCgUAAAAAOUsrRC5tTdqYIAoTYBtLj_b1vb2').then((recaptcha) => {
            recaptcha.execute('<action>').then((token) => {
                console.log(token);
            })
        });*/
    //popup
    $body.on('click',  '[data-popup]', function (e) {
        e.preventDefault();
        spnPopupShow($(this).data('popup'));
    });
    $body.on('click',  '[data-popclose]', function (e) {
        e.preventDefault();
        popFade();
    });
    $body.on('click',  '#popup-block', function (e) {
        e.preventDefault();
        if (event.target.id === 'popup-block') {
            popFade();
        }
    });

    function popFade() {
        $('#popup-block').fadeOut(100, function(){
            $body.removeClass('popup');
            $('.popup-block__content').html('');
        });
    }
    //scroll to
    $body.on('click',  'a[href="#white-block"]', function (e) {
        e.preventDefault();
        window.scrollTo({
            top: $('#white-block-content').offset().top,
            left: 0,
            behavior: 'smooth'
        });
    });
    //eye animation
    var eye = eyeAnimate();

    $body.on('click', '.eye-animation', function(){
        eye($(this));
    });

    function eyeAnimate(el) {
        var element = el;
        var toggle = true;
        var shapes = [
            {
                'stroke-width': "2",
                'width': "25.2431",
                'transform': "matrix(-0.707107 0.707107 0.707107 0.707107 20.7126 2.58579)"
            },
            {
                'stroke-width': "2",
                'width': "3.41099",
                'transform': "matrix(-0.707107 0.707107 0.707107 0.707107 13 10)"
            },
            {
                'stroke-width': "0",
                'width': "3.41099",
                'transform': "matrix(-1.3 1.3 1.3 1.3 13.8 9.1)"
            }
        ];
        return function toggleAnimate(element) {
            var shapesArr = [];
            shapesArr = JSON.parse(JSON.stringify(shapes));
            var pass = element.closest('.position-relative').find('[name="password"]');
            if (!toggle) {
                shapesArr.reverse();
                pass.attr('type', 'password');
            } else {
                pass.attr('type', 'text');
            }
            toggle = !toggle;

            anime({
                targets: '.eye-animation rect',
                'stroke-width': [
                    {value: shapesArr[0]['stroke-width']},
                    {value: shapesArr[1]['stroke-width']},
                    {value: shapesArr[2]['stroke-width']}
                ],
                'width': [
                    {value: shapesArr[0]['width']},
                    {value: shapesArr[1]['width']},
                    {value: shapesArr[2]['width']}
                ],
                'transform': [
                    {value: shapesArr[0]['transform']},
                    {value: shapesArr[1]['transform']},
                    {value: shapesArr[2]['transform']},
                ],
                duration: 250,
                autoplay: true,
                easing: 'linear',
                elasticity: 500,
            });
        }
    }


    var
        el1 = document.querySelectorAll('.animate-smaller .cover-img')[0],
        el2 = document.querySelectorAll('.inner-white')[0],
        tl = anime.timeline({ autoplay: false });

    tl.add({
        targets: el1,
        scale: 1.3,
        duration: 700, easing: 'linear',
    }, 0).add({
        targets: el2,
        width: 100 + 'vw',
        duration: 500, easing: 'linear',
    }, 0);
    tl.seek($(document).scrollTop());

    $(window).on('resize', () => {
        correctHeight();
    });
    $(window).on('scroll', function () {
        fixedMenu();
        if (doNotShowBtns) {
            const to_show = $('html').scrollTop() > 50;
            if (to_show) {
                if (showButtons_is !== true) {
                    showButtons_is = true;
                    $showButtons.fadeIn().addClass('active');
                }
            } else if (showButtons_is !== false ) {
                showButtons_is = false;
                $showButtons.fadeOut().removeClass('active');
            }
        } else if (showButtons_is !== false ) {
            showButtons_is = false;
            $showButtons.fadeIn().addClass('active');
        }
        tl.seek($(document).scrollTop());
    }).trigger('scroll');

    if ($('.inner-white').length) {
        setTimeout(function () {
            correctHeight();
        }, 100);
    }

    if ( typeof needToShowForget !== 'undefined' && needToShowForget === true ){
        spnPopupShow('forget');
    }
    $('[data-toggle="popover"]').popover();

    const $cookieBtn = $('a[href="#cookies-btn"]');
    if (localStorage.getItem('cookieStatus') !== 'y') {
        $cookieBtn.on('mouseenter', e => {
            $(e.target).text('Я согласен');
        }).on('mouseleave', e => {
            $(e.target).text('Cookies');
        }).on('click', e => {
            e.preventDefault();
            localStorage.setItem('cookieStatus', 'y');
            $(e.target).closest('.cookies-block').removeClass('d-sm-inline-block');
        }).closest('.cookies-block').addClass('d-sm-inline-block');
    }

});
